import React from "react";
import "./About-Us.scss";
import TextLoop from "react-text-loop";
import { Animated } from "react-animated-css";
import image from "../../images/fachada.jpg";
import { Link } from "react-router-dom";

export const AboutUs = () => {
  const phrases = [
    { text: "tu marca sea inolvidable", color: "#EB2B8F" },
    { text: "tu comunidad en redes crezca", color: "#ed2649" },
    { text: "tu campaña alcance su meta", color: "#f26e21" },
    { text: "tu sitio web sea efectivo", color: "#f19d23" },
    { text: "tu empaque eleve tu ventas", color: "#ffc10d" },
    { text: "tu mensaje sea transcendente", color: "#91af14" },
    { text: "te mantengamos a la vanguardia", color: "#0085ff" }
  ];
  return (
    <Animated className="AboutLayout">
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <img src={image} alt="" className="img-fluid" />
        <div className="AboutTitle">
          <p>Sabemos que quieres que </p>
          <TextLoop
            springConfig={{ stiffness: 100, damping: 9 }}
            className="font-weight-bolder"
            // noWrap={false}
          >
            {phrases.map(({ text, color }) => (
              <span style={{ color }} key={text}>
                {text}
              </span>
            ))}
          </TextLoop>
          <p>y tambien sabemos como lograrlo</p>
        </div>
        <div className="text-wk-right">
          <div className="AboutText">
            <p>
              Meta: (1) pref: (del griego. Metá), más allá, después, por el
              medio, y también cambio o mutación.
            </p>
            <br />
            <p>
              (2) agcia: (del creativo Beyond), grupo de profesionales con
              experiencia en branding, publicidad, programación y diseño
              reunidos bajo el nombre de un nuevo estudio creativo, que junta
              sus talentos e ideas en función de hacer llegar las marcas aliadas
              hasta el siguiente nivel.
            </p>
            <br />
            <div className="text-right">
              <p className="mini-text">
                (Definiciones no aprobadas por la RAE… todavía)
              </p>
            </div>
            <p style={{ paddingBottom: "70px" }}>
              Haz click
              <Link
                to={{ pathname: "/services" }}
                style={{ textDecoration: "none" }}
              >
                {` aquí `}
              </Link>
              para conocer más acerca de nuestros servicios
            </p>
          </div>
        </div>
      </Animated>
    </Animated>
  );
};
